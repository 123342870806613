<template>
  <div>
    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="serviceOrders">
          Service Orders
        </h1>
        <CCard class="">
          <CCardBody class="px-0">
            <AjaxTable
                :fields="fields"
                :data="serviceOrders"
                :fetch="fetchServiceOrderList"
            >
              <template #paid_at="{item}">
                <td>
                  {{ item.paid_at|formatDate('LL',userTimeZone) }}
                </td>
              </template>
              <template #pica_service="{item}">
                <td>
                  {{ item.pica_service.name }}
                </td>
              </template>
              <template #total_amount="{item}">
                <td>
                  {{ item.total_amount|currency(item.currency) }}
                </td>
              </template>
              <template #actions="{item}">
                <td class="text-right">
                  <CButton @click="downloadInvoice(item.id)" variant="outline" color="danger"
                           v-if="hasPerm('contract_manager.service_order.download_invoice') && item.has_invoice"
                           v-translate translate-context="list.actions"
                  >
                    Download Invoice
                  </CButton>
                </td>
              </template>
            </AjaxTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AjaxTable from "@/domain/core/components/AjaxTable.vue";

export default {
  name: 'ListServiceOrders',
  components: {AjaxTable},
  async mounted() {
    await this.fetchConfig({type: 'contract_manager'})
  },
  computed: {
    ...mapGetters('serviceOrders', [
      'serviceOrders',
    ]),
    ...mapGetters('login', [
      'userTimeZone'
    ]),
    fields() {
      let fields = [
        {key: 'id', label: this.$pgettext('serviceOrders.list', 'ID'), _style: 'width:100px;'},
        {key: 'paid_at', label: this.$pgettext('serviceOrders.list', 'Date'),sorter: false, filter: false },
        {key: 'pica_service', label: this.$pgettext('serviceOrders.list', 'Event'),sorter: false, filter: false },
        {key: 'description', label: this.$pgettext('serviceOrders.list', 'Description'),sorter: false, filter: false },
        {key: 'total_amount', label: this.$pgettext('serviceOrders.list', 'Total Amount') ,sorter: false, filter: false },
      ]
      if (this.hasAnyPerms(['contract_manager.service_order.download_invoice']))
        fields.push({
          key: 'actions',
          label: this.$pgettext('serviceOrders.list', 'Actions'),
          sorter: false,
          filter: false
        })
      return fields
    }
  },
  methods: {
    ...mapActions('serviceOrders', [
      'fetchServiceOrderList',
      'downloadInvoice'
    ]),
    ...mapActions('login', [
      'fetchConfig'
    ]),
  }
}
</script>