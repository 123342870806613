var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"main-header",attrs:{"translate-context":"serviceOrders"}},[_vm._v(" Service Orders ")]),_c('CCard',{},[_c('CCardBody',{staticClass:"px-0"},[_c('AjaxTable',{attrs:{"fields":_vm.fields,"data":_vm.serviceOrders,"fetch":_vm.fetchServiceOrderList},scopedSlots:_vm._u([{key:"paid_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.paid_at,'LL',_vm.userTimeZone))+" ")])]}},{key:"pica_service",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.pica_service.name)+" ")])]}},{key:"total_amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("currency")(item.total_amount,item.currency))+" ")])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[(_vm.hasPerm('contract_manager.service_order.download_invoice') && item.has_invoice)?_c('CButton',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"variant":"outline","color":"danger","translate-context":"list.actions"},on:{"click":function($event){return _vm.downloadInvoice(item.id)}}},[_vm._v(" Download Invoice ")]):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }